import React, { Fragment, useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { isTablet, isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
import { Box, Button, Link, Typography, Skeleton } from "@mui/material";
import moment from "moment";
//// components
import NewRecord from "../../login/NewRecord";
import AddressSection from "../../base/components/AddressSection";
import CreditCardForm from "../../base/components/CreditCardForm";
import CheckboxComponent from "../../base/components/CheckboxComponent";
import SelectComponent from "../../base/components/SelectComponent";
import SummaryCheckout from "../Components/SumaryCheckout";
import CenteredModal from "../../base/components/CenteredModal";
import NewLoginContainer from "../../login/components/NewRecord/NewLoginContainer";
/////UTILS
import { convertNameToInitials } from "../../../../Libraries/Utils/utils";
import PayPalButton from "../../../../Libraries/Utils/PayPalButton";
///// assets
import AvatarPlaceholder from "./../../../../assets/media/images/avatarPlaceholder.jpg";
import { ReactComponent as PaySecurityIcon } from "./../../../../assets/media/images/icons/paySecurityIcon.svg";
import { ReactComponent as BoxOpenIcon } from "./../../../../assets/media/images/icons/boxOpenIcon.svg";
import { ReactComponent as SurpriseIcon } from "./../../../../assets/media/images/icons/surpriseIcon.svg";
import AssignEntrepreneur from "../../login/components/NewRecord/AssignEntrepreneur";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  cleanCartClosedOrder,
  cleanCartInventoryAssingReducer,
  cleanReducerCartOrderCharges,
  cleanReducerOrderTaxesCalculation,
  requestCarCalculateOrderTax,
  requestCarCurrentOrder,
  requestCartAssignInventory,
  requestCartCancelOrder,
  requestCartOrderCharges,
  requestCloseCartOrder,
  requestGetCartClosedOrder,
  requestGetCartCouponList,
  requestGetCartOrder,
} from "../../../ReduxSaga/Actions/Cart";
import { isEqual } from "lodash";
import {
  requestFirstChargeSiteReload,
  requestLoggUserActivity,
} from "../../../ReduxSaga/Actions/General";
import {
  requestCleanGetDataSponsorsListReducer,
  requestCleanUserDataValidation,
  requestGetDataSponsorById,
  requestNewRegistrationCheckoutData,
  requestUpdateAddressCheckoutData,
  requestUserDataValidation,
} from "../../../ReduxSaga/Actions/Client";
import {
  requestCleanPaymentReducer,
  requestStripePayment2023,
} from "../../../ReduxSaga/Actions/Payment";
import Payment from "../../../../Libraries/Payment";
import { useRef } from "react";

const CheckOutContainer = ({
  //isLanguageLoaded,
  //isProfileImageLoaded,
  //imageProfileUrl,
  //clientInfoToCloseOrder,
  //handleGAEvent,
  //////
  infoCartOrder,
  benefitType,
  guestInfoToCloseOrder,
  setGuestInfoToCloseOrder,
  clientInfoToCloseOrder,
  setClientInfoToCloseOrder,
  handleGAEvent,
  handleResetInfoClient,
  handleResetInfoGuestForm,
  isLanguageLoaded,
  isProfileImageLoaded,
  imageProfileUrl,
  isWebpage,
  buildVCardSponsorSelection,
}) => {
  //Notistack
  const { enqueueSnackbar } = useSnackbar();

  //Redux
  const dispatch = useDispatch();
  const appReducers = {};
  appReducers.general = useSelector((state) => state.general);
  appReducers.client = useSelector((state) => state.client);
  appReducers.cart = useSelector((state) => state.cart);
  appReducers.address = useSelector((state) => state.address);
  appReducers.payment = useSelector((state) => state.payment);
  appReducers.home = useSelector((state) => state.home);

  //Translation
  const { t, i18n } = useTranslation();
  ///history
  const history = useHistory();
  const location = useLocation();

  const paypalRef = useRef();

  ////state
  const [orderLines, setOrderLines] = useState([]);
  const [order, setOrder] = useState({});
  const [isCreditCard, setIsCreditCard] = useState(true);
  const [wayToPAy, setWayToPay] = useState("credit");
  const [isPaypalPay, setIsPaypalPay] = useState(false);
  const [shippingPass, setShippingPass] = useState(false);
  const [isTaxesActive, setIsTaxesActive] = useState(false);
  const [securityPass, setSecurityPass] = useState(false);
  const [creditCardData, setCreditCardData] = useState({
    cardNumber: "",
    cardExpirationDate: "",
    cardCvc: "",
  });
  const [orderCloseType, setOrderCloseType] = useState("");
  const [previousSessionId, setPreviousSessionId] = useState(0);
  /// loading
  const [isLoadingEnabled, setIsLoadingEnabled] = useState(true);
  const [couponList, setCouponList] = useState([]);
  const [typeOfExtra, setTypeOfExtra] = useState("");
  const [blockByPayingOp, setBlockByPayingOp] = useState(false);
  const [activeFieldLock, setActiveFieldLock] = useState(false);
  const [blockByOrderCancelling, setBlockByOrderCancelling] = useState(false);
  const [clientCouponSelected, setClientCouponSelected] = useState({});
  // PAYPAL
  const [paypalStatusPay, setPaypalStatusPay] = useState("");

  //// modal validation userData
  const [isOpenValidationDataModal, setIsOpenValidationDataModal] =
    useState(false);
  const [userDataValidationErrors, setUserDataValidationErrors] = useState([]);
  const [webpageInfo, setWebpageInfo] = useState({});

  //// modal login
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);
  /// modal complete
  const [isOpenCongraModal, setIsOpenCongraModal] = useState(false);

  //New
  const [gender, setGender] = useState("F");
  const [formData, setFormData] = useState({
    name: {
      error: false,
      isEmpty: true,
      value: "",
    },
    lastname: {
      error: false,
      isEmpty: true,
      value: "",
    },
    birthdate: {
      error: false,
      isEmpty: false,
      value: moment().subtract(18, "years"),
    },
    email: {
      error: false,
      isEmpty: true,
      value: "",
      isValid: false,
    },
    cellphone: {
      error: false,
      isEmpty: true,
      value: "",
      isValid: false,
      isSendedSMS: false,
      isValidSMSCode: false,
      smsCodeTyped: "",
    },
    //isShipmentAddressVerified: false,
    //isFiscalAddressVerified: false,
    shippStreet: {
      error: false,
      isEmpty: true,
      value: "",
    },
    shippSuite: {
      error: false,
      isEmpty: true,
      value: "",
    },
    shippCity: {
      error: false,
      isEmpty: true,
      value: "",
    },
    shippState: {
      error: false,
      isEmpty: true,
      value: "",
    },
    shippZipCode: {
      error: false,
      isEmpty: true,
      value: "",
    },
    fiscalStreet: {
      error: false,
      isEmpty: true,
      value: "",
    },
    fiscalSuite: {
      error: false,
      isEmpty: true,
      value: "",
    },
    fiscalCity: {
      error: false,
      isEmpty: true,
      value: "",
    },
    fiscalState: {
      error: false,
      isEmpty: true,
      value: "",
    },
    fiscalZipCode: {
      error: false,
      isEmpty: true,
      value: "",
    },
  });
  const [addressControl, setAddressControl] = useState({
    isSameAddress: true,
    isShippValidAddress: false,
    isFiscalValidAddress: false,
    addressSugestions: [],
    isOpenModalAddressSuggestion: false,
    selectedAddressInModalAddressSuggestion: {},
  });
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] =
    useState(false);
  const sponsorInitialInformation = {
    name: "",
    noEnterprising: 0,
    avatar: "",
    city: "",
    state: "",
  };
  const [sponsorInformation, setSponsorInformation] = useState(
    sponsorInitialInformation
  );

  const [paypalStep, setPaypalStep] = useState(1);

  //Effects
  //Efecto inicial, solicitamos actualizar el inventario
  useEffect(() => {
    document.title = "Zermat USA :: Checkout";
    handleAssignInventory();
    dispatch(cleanCartClosedOrder());
  }, []);
  //Efecto para controlar el tipo de cliente
  useEffect(() => {
    if (
      benefitType === 1 &&
      parseInt(JSON.parse(localStorage.getItem("ZUsaUInfo")).typeUser) === 2
    ) {
      //Cargar el sponsor desde el localStorage
      const sponsorId = parseInt(
        JSON.parse(localStorage.getItem("ZUsaUInfo")).sponsorId
      );
      console.log("sponsorId", sponsorId);
      if (sponsorId) {
        dispatch(requestGetDataSponsorById(sponsorId));
      }
    }

    if (typeof appReducers?.general?.errorLoadFirstCharge !== "undefined") {
      if (!appReducers.general.errorLoadFirstCharge) {
        const { sessionId } = appReducers.general.firstChargeModel;
        setPreviousSessionId(sessionId);
        //Benefit Types
        //5 = INVITADO / CLIENTE FINAL
        //2 = CLIENTE PREFERIDO
        //1 = MULTINIVEL
        //3 = EMPLEADOS (EN DESUSO)
        /////////////////////////
        console.log("benefitType", benefitType, typeof benefitType);
        console.log("clientInfoToCloseOrder", clientInfoToCloseOrder);
        if (benefitType === 5) {
          setOrderCloseType("finalClient");
        } else if (benefitType === 2) {
          setOrderCloseType("preferredClient");
        } else if (benefitType === 1) {
          setOrderCloseType("entreprenour");
        }

        if (
          typeof appReducers?.client?.errorLoadClientLogedInformation ===
          "undefined"
        ) {
          //CLIENTE NO LOGUEADO (SIN MEMBRESIA ANTES DE CERRAR LA ORDEN)
          console.log("CLIENTE NO LOGUEADO");
        } else {
          //CLIENTE LOGUEADO
          console.log("CLIENTE LOGUEADO");
          //TODO: LLENAR LOS DATOS DE LAS DIRECCIONES CON LO QUE VIENE DE 'clientInfoToCloseOrder' Y MARCAR COMO READONLY
          // SI LE FALTA LA DIRECCION FISCAL PORQUE SE REGISTRO DESDE EL LOGIN, SOLO MOSTRAR LA DIRECCION FISCAL

          const {
            shippStreet,
            shippSuite,
            shippCity,
            shippState,
            shippZip,
            fiscalStreet,
            fiscalSuite,
            fiscalCity,
            fiscalState,
            fiscalZip,
            withShippmentAddress,
            withFiscalAddress,
          } = clientInfoToCloseOrder;

          setFormData({
            ...formData,
            shippStreet: {
              error: false,
              isEmpty: false,
              value: shippStreet,
            },
            shippSuite: {
              error: false,
              isEmpty: false,
              value: shippSuite,
            },
            shippCity: {
              error: false,
              isEmpty: false,
              value: shippCity,
            },
            shippState: {
              error: false,
              isEmpty: false,
              value: shippState,
            },
            shippZipCode: {
              error: false,
              isEmpty: false,
              value: shippZip,
            },
            fiscalStreet: {
              error: false,
              isEmpty: false,
              value: fiscalStreet,
            },
            fiscalSuite: {
              error: false,
              isEmpty: false,
              value: fiscalSuite,
            },
            fiscalCity: {
              error: false,
              isEmpty: false,
              value: fiscalCity,
            },
            fiscalState: {
              error: false,
              isEmpty: false,
              value: fiscalState,
            },
            fiscalZipCode: {
              error: false,
              isEmpty: false,
              value: fiscalZip,
            },
          });

          if (
            isEqual(shippStreet, fiscalStreet) &&
            isEqual(shippSuite, fiscalSuite) &&
            isEqual(shippCity, fiscalCity) &&
            isEqual(shippState, fiscalState) &&
            isEqual(shippZip, fiscalZip)
          ) {
            ///
            console.log("direcciones iguales");
            setAddressControl({
              ...addressControl,
              isSameAddress: true,
              isShippValidAddress:
                withShippmentAddress && shippStreet.length > 0,
              isFiscalValidAddress:
                withFiscalAddress && fiscalStreet.length > 0,
              isOpenModalAddressSuggestion: false,
            });
          } else {
            setAddressControl({
              ...addressControl,
              isSameAddress: false,
              isShippValidAddress:
                withShippmentAddress && shippStreet.length > 0,
              isFiscalValidAddress:
                withFiscalAddress && fiscalStreet.length > 0,
              isOpenModalAddressSuggestion: false,
            });
          }

          //FIXME: Calcular los taxes
          // if (shippZip.length > 0 && !isEqual(shippZip, shippZipCode)) {
          if (shippZip.length > 0) {
            hanldeTaxCalculationOnOrder(shippZip);
          }

          dispatch(requestGetCartCouponList());
        }
      }
    }
  }, [
    appReducers?.general.errorLoadFirstCharge,
    appReducers?.general.firstChargeModel,
    appReducers?.client.errorLoadClientLogedInformation,
    benefitType,
    clientInfoToCloseOrder,
    //guestInfoToCloseOrder,
  ]);
  //Efecto para cargar los productos de la orden
  useEffect(() => {
    console.log("infoCartOrder", infoCartOrder);
    if (infoCartOrder?.hasOwnProperty("code") && infoCartOrder?.code === 100) {
      if (infoCartOrder?.order && infoCartOrder?.order !== null) {
        if (infoCartOrder?.order.importeii <= 0) {
          history.replace("/categories");
        } else {
          setOrder(infoCartOrder?.order);
          setOrderLines(infoCartOrder?.lines);
          //setOrderCharges(infoCartOrder?.charges);
          if (
            infoCartOrder?.charges.find((e) => e.idCharge === 7) &&
            !securityPass
          )
            setSecurityPass(true);
          if (
            infoCartOrder?.charges.find((e) => e.idCharge === 18) &&
            !shippingPass
          )
            setShippingPass(true);
        }
      } else {
        history.replace("/categories");
      }
    }
  }, [infoCartOrder]);
  //Efecto para cargar los cupones
  useEffect(() => {
    console.log("cartCouponList", appReducers?.cart?.cartCouponList);
    if (appReducers?.cart?.cartCouponList) {
      const clist = appReducers.cart.cartCouponList;
      if (clist.code === 100) {
        setCouponList(clist.couponList);
      }
    }
  }, [appReducers?.cart.cartCouponList]);
  //Efecto para calcular los cargos (DELIVERY CONFIRMATION OR SHIPPING PASS)
  useEffect(() => {
    if (typeof appReducers?.cart?.errorCartOrderCharges !== "undefined") {
      if (!appReducers.cart.errorCartOrderCharges) {
        enqueueSnackbar(
          typeOfExtra === "signatureAdd"
            ? t("shopping__payment__extra__secury_pass_add_message")
            : typeOfExtra === "signatureDel"
            ? t("shopping__payment__extra__secury_pass_delete_message")
            : typeOfExtra === "shippingPassAdd"
            ? t("shopping__payment__extra__shipping_pass_add_message")
            : typeOfExtra === "shippingPassDel"
            ? t("shopping__payment__extra__shipping_pass_delete_message")
            : "",
          { variant: "success" }
        );
        setTypeOfExtra("");
        dispatch(cleanReducerCartOrderCharges());
        dispatch(requestGetCartOrder());
      } else {
        enqueueSnackbar(
          typeOfExtra === "signatureAdd"
            ? t("shopping__payment__extra__secury_pass_error_add_message")
            : typeOfExtra === "signatureDel"
            ? t("shopping__payment__extra__secury_pass_error_delete_message")
            : typeOfExtra === "shippingPassAdd"
            ? t("shopping__payment__extra__shipping_pass_error_add_message")
            : typeOfExtra === "shippingPassDel"
            ? t("shopping__payment__extra__shipping_pass_error_delete_message")
            : "",
          { variant: "error" }
        );
      }
    }
  }, [appReducers?.cart.errorCartOrderCharges, typeOfExtra]);
  //Efecto al actualizar el inventario
  useEffect(() => {
    if (typeof appReducers?.cart?.errorCartInventoryAssign !== "undefined") {
      if (!appReducers?.cart?.errorCartInventoryAssign) {
        dispatch(cleanCartInventoryAssingReducer());
        dispatch(requestGetCartOrder());
        console.log("se asignó inventario de forma correcta");
      } else {
        //error
        console.error("se asignó inventario de forma incorrecta");
      }
    }
  }, [
    appReducers?.cart.errorCartInventoryAssign,
    appReducers?.cart.cartInventoryAssign,
  ]);

  //TODO: Efecto al solicitar el registro
  //TODO: Efecto despues de la asignación del sponsor para obtener el beneficio del emprendedor
  //TODO: Efecto al pagar la orden
  //TODO: Efecto al cerrar la orden
  //TODO: (NO SE NECESITA) Efecto para obtener estados y ciudades

  //Efecto al actualizar los taxes
  useEffect(() => {
    if (typeof appReducers?.cart?.errorCartTaxOrder !== "undefined") {
      if (appReducers.cart.errorCartTaxOrder) {
        enqueueSnackbar(
          t("shopping__payment__error_taxes_calc_order_message"),
          {
            variant: "warning",
          }
        );
      } else {
        dispatch(cleanReducerOrderTaxesCalculation());
        dispatch(requestGetCartOrder());
      }
    }
  }, [appReducers?.cart.errorCartTaxOrder, appReducers?.cart.cartTaxOrder]);
  //Efecto al solicitar firma en la entrega
  useEffect(() => {
    if (securityPass) setTypeOfExtra("signatureAdd");
    else setTypeOfExtra("signatureDel");
  }, [securityPass]);
  //Efecto al solicitar shipping pass
  useEffect(() => {
    if (shippingPass) setTypeOfExtra("shippingPassAdd");
    else setTypeOfExtra("shippingPassDel");
  }, [shippingPass]);

  //TODO: (NO SE NECESITA) Efecto al validar la direccion
  //TODO: Efecto al cancelar la orden
  //TODO: (NO SE NECESITA) Efecto del checkbox cuando es la misma direccion
  //TODO: (NO SE NECESITA) Efecto al estar validada la direccion fiscal

  //Efecto para validar que la data no este duplicada
  useEffect(() => {
    if (
      typeof appReducers?.client?.userDataValidationResponse !== "undefined"
    ) {
      if (appReducers.client.userDataValidationResponse.code === 100) {
        if (
          appReducers.client.userDataValidationResponse.validationList.length >
          0
        ) {
          setBlockByPayingOp(false);
          setIsOpenValidationDataModal(true);
          setUserDataValidationErrors(
            appReducers.client.userDataValidationResponse.validationList
          );
        } else {
          setIsOpenValidationDataModal(false);
          setUserDataValidationErrors([]);

          //TODO: Hacer dispatch del registro
          const date = new Date(formData.birthdate.value);
          const formatedBirthDate = moment(date).format("YYYY-MM-DD");
          const model = {
            name: formData.name.value,
            lastname: formData.lastname.value,
            dateBorn: formatedBirthDate,
            email: formData.email.value,
            cellPhone: formData.cellphone.value,
            gender:
              clientInfoToCloseOrder?.gender === ""
                ? gender
                : clientInfoToCloseOrder.gender,
            idLang: i18n.language === "es" ? 1 : 2,
            streetS: formData.shippStreet.value,
            suiteS: formData.shippSuite.value.trim(),
            cityS: formData.shippCity.value,
            stateS: formData.shippState.value,
            zipS: formData.shippZipCode.value,
            streetF: formData.fiscalStreet.value,
            suiteF: formData.fiscalSuite.value.trim(),
            cityF: formData.fiscalCity.value,
            stateF: formData.fiscalState.value,
            zipF: formData.fiscalZipCode.value,
            patrocinador: sponsorInformation.noEnterprising,
            typePlan: benefitType,
          };
          console.log("requestNewRegistrationCheckoutData model", model);
          dispatch(requestNewRegistrationCheckoutData(model));
        }
      } else {
        setBlockByPayingOp(false);
        setIsOpenValidationDataModal(false);
        setUserDataValidationErrors([]);
        enqueueSnackbar(
          t("Error al validar los datos proporcionados; intenta de nuevo :)"),
          { variant: "error" }
        );
      }
      dispatch(requestCleanUserDataValidation());
    }
  }, [appReducers?.client?.userDataValidationResponse]);

  //Efecto para obtener la informacion de la pagina web
  useEffect(() => {
    if (appReducers?.home?.entrepenourPageInfo) {
      setWebpageInfo(appReducers.home.entrepenourPageInfo);
    } else {
      setWebpageInfo({});
    }
  }, [appReducers?.home?.entrepenourPageInfo]);

  //Efecto al recibir la respuesta despues de registrar al usuario
  useEffect(() => {
    if (
      typeof appReducers?.client?.newRegistrationCheckoutData !== "undefined"
    ) {
      const response =
        appReducers?.client?.newRegistrationCheckoutData.response;
      if (response?.code === 100) {
        //Despachar la accion de pago y cierre de orden
        handleDispatchPaymentAndOrderClose();
      } else {
        setBlockByPayingOp(false);
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    }
  }, [appReducers?.client?.newRegistrationCheckoutData]);

  //Efecto al recibir la respuesta del pago por Stripe
  useEffect(() => {
    if (appReducers?.payment?.responseStripePayment) {
      const payment = appReducers.payment.responseStripePayment;
      if (payment.code === 100) {
        enqueueSnackbar(t("__message_pay_success_label"), {
          variant: "success",
        });

        dispatch(requestFirstChargeSiteReload(payment.user));

        dispatch(cleanCartClosedOrder());
        history.replace(`/summary/${previousSessionId}/${orderCloseType}`);
      } else {
        setBlockByPayingOp(false);
        enqueueSnackbar(t("__message_order_payment_label_card_number_error"), {
          variant: "error",
        });
      }
      dispatch(requestCleanPaymentReducer());
    }
  }, [appReducers?.payment.responseStripePayment]);

  //Efecto al recibir la respuesta de la actualización de las direcciones
  useEffect(() => {
    const response = appReducers?.client?.updateAddressCheckoutData;
    if (typeof response !== "undefined") {
      if (response.code === 100) {
        console.log("Se actualizaron las direcciones correctamente");
        //Despachar la accion de pago y cierre de orden
        handleDispatchPaymentAndOrderClose();
      } else {
        setBlockByPayingOp(false);
        enqueueSnackbar(response.message, { variant: "error" });
      }
    }
  }, [appReducers?.client?.updateAddressCheckoutData]);

  //Efecto que regresa la busqueda del sponsor para hacer la VCard de Cliente Preferido
  useEffect(() => {
    if (appReducers?.client?.assignedSponsors) {
      let respSponsors = appReducers.client.assignedSponsors;
      if (respSponsors.code === 100) {
        console.log(
          "SPONSORS DESDE EL CHECKOUT",
          respSponsors.assignedSponsors
        );
        const firstSponsor = respSponsors.assignedSponsors[0];
        //setSponsorResult(respSponsors.assignedSponsors);
        if (firstSponsor && firstSponsor.length > 0) {
          buildVCardSponsorSelection(firstSponsor);
        }

        //limpiar el reducer
        dispatch(requestCleanGetDataSponsorsListReducer());
      }
    }
  }, [appReducers?.client?.assignedSponsors]);

  //////////////////////////////////////////////////////////////////////
  //Handlers
  const handleWayToPayChange = (event) => {
    setWayToPay(event.target.value);
    if (event.target.value === "paypal") {
      setIsPaypalPay(true);
      setIsCreditCard(false);
    }
    if (event.target.value === "credit") {
      setIsPaypalPay(false);
      setIsCreditCard(true);
    }
  };
  const handleTaxes = () => {
    // console.log("me estoy handel taxeando");
    setIsTaxesActive(!isTaxesActive);
  };
  const handleAssignInventory = () => {
    const parametersInventory = {
      idPedidoDet: 0,
      idpromo: 0,
      itemCode: "",
      opc: 10,
      pedidosD: [],
      quantity: 0,
      type: "",
      withAssign: 0,
    };
    dispatch(requestCartAssignInventory(parametersInventory));
  };
  const handleShippingPass = (e) => {
    setShippingPass(e.target.checked);
    if (e.target.checked) {
      dispatch(requestCartOrderCharges(4, order.orderId));
      dispatch(
        requestLoggUserActivity(
          57,
          0,
          0,
          "",
          "Se agrego shipping pass a la orden"
        )
      );
    } else {
      dispatch(requestCartOrderCharges(5, order.orderId));
      dispatch(
        requestLoggUserActivity(
          57,
          0,
          0,
          "",
          "Se elimino shipping pass a la orden"
        )
      );
    }
  };
  const handleSecurityPass = (e) => {
    setSecurityPass(e.target.checked);
    if (e.target.checked) {
      dispatch(requestCartOrderCharges(2, order.orderId));
      dispatch(
        requestLoggUserActivity(57, 0, 0, "", "Se agrego cargo de firma")
      );
    } else {
      dispatch(requestCartOrderCharges(3, order.orderId));
      dispatch(
        requestLoggUserActivity(57, 0, 0, "", "Se elimino cargo de firma")
      );
    }
  };
  const handleClientCouponSelection = (event) => {
    if (event.target.value !== "") {
      setClientCouponSelected(event.target.value);
      const parameters = {
        idPedidoDet: 0,
        idpromo: event.target.value.idCoupon,
        itemCode: event.target.value.itemCode,
        opc: 17,
        pedidosD: [],
        quantity: 1,
        type: "",
        withAssign: 0,
      };
      dispatch(requestCarCurrentOrder(parameters, "addClientCoupon"));
    }
  };
  const hanldeTaxCalculationOnOrder = (zipCode) => {
    const parameters = {
      idPedidoDet: 0,
      idpromo: 0,
      itemCode: "",
      opc: 18,
      pedidosD: [],
      quantity: 0,
      type: "",
      withAssign: 0,
      zipCode: zipCode,
    };
    dispatch(requestCarCalculateOrderTax(parameters));
  };

  const handleIsDisabledPayButton = () => {
    const data = {
      name: formData.name.value,
      gender:
        clientInfoToCloseOrder?.gender === ""
          ? gender
          : clientInfoToCloseOrder.gender,
      lastname: formData.lastname.value,
      birthdate: formData.birthdate.value,
      email: formData.email.value,
      emailIsValid: formData.email.isValid,
      cellphone: formData.cellphone.value,
      cellphoneIsValid: formData.cellphone.isValid,
      acceptTermsAndConditions,
      shippStreet: formData.shippStreet.value,
      shippSuite: formData.shippSuite.value.trim(),
      shippState: formData.shippState.value,
      shippCity: formData.shippCity.value,
      shippZipCode: formData.shippZipCode.value,
      fiscalStreet: formData.fiscalStreet.value,
      fiscalSuite: formData.fiscalSuite.value.trim(),
      fiscalState: formData.fiscalState.value,
      fiscalCity: formData.fiscalCity.value,
      fiscalZipCode: formData.fiscalZipCode.value,
      isShipmentAddressVerified: addressControl.isShippValidAddress,
      isFiscalAddressVerified: addressControl.isFiscalValidAddress,
      patrocinador: sponsorInformation.noEnterprising,
      typePlan: benefitType,
    };
    //console.clear();
    //console.log("handleIsDisabledPayButton Data: ", data);

    const isValidNotLoguedInGeneralData =
      !formData.name.error &&
      !formData.name.isEmpty &&
      !formData.lastname.error &&
      !formData.lastname.isEmpty &&
      !formData.birthdate.error &&
      !formData.birthdate.isEmpty &&
      !formData.email.error &&
      formData.email.isValid &&
      !formData.cellphone.error &&
      formData.cellphone.isValid &&
      !formData.cellphone.value.length <= 0 &&
      formData.cellphone.isValidSMSCode &&
      acceptTermsAndConditions;

    const isValidAddressData =
      addressControl.isShippValidAddress && addressControl.isFiscalValidAddress;

    const isValidMultilevelData =
      benefitType === 1
        ? sponsorInformation.noEnterprising !== 0
        : sponsorInformation.noEnterprising === 0;

    const isValidPaymentData =
      !isCreditCard && isPaypalPay
        ? true
        : creditCardData.cardNumber !== "" &&
          creditCardData.cardExpirationDate !== "" &&
          creditCardData.cardCvc !== "";

    // console.log("Validations result:", {
    //   isValidNotLoguedInGeneralData,
    //   isValidAddressData,
    //   isValidMultilevelData,
    //   isValidPaymentData,
    // });

    if (!clientInfoToCloseOrder.name) {
      //1. No esta logueado
      switch (benefitType) {
        case 5:
          return (
            !isValidNotLoguedInGeneralData ||
            !isValidAddressData ||
            !isValidPaymentData
          );
        case 2:
          return (
            !isValidNotLoguedInGeneralData ||
            !isValidAddressData ||
            !isValidPaymentData
          );
        case 1:
          if (
            parseInt(JSON.parse(localStorage.getItem("ZUsaUInfo")).idPage) === 0
          ) {
            return (
              !isValidNotLoguedInGeneralData ||
              !isValidAddressData ||
              !isValidMultilevelData ||
              !isValidPaymentData
            );
          }

          return (
            !isValidNotLoguedInGeneralData ||
            !isValidAddressData ||
            !isValidPaymentData
          );

        default:
      }
    } else {
      //2. Si esta logueado
      switch (benefitType) {
        case 5:
          return !isValidAddressData || !isValidPaymentData;
        case 2:
          return !isValidAddressData || !isValidPaymentData;
        case 1:
          if (
            parseInt(JSON.parse(localStorage.getItem("ZUsaUInfo")).typeUser) !==
              2 &&
            parseInt(JSON.parse(localStorage.getItem("ZUsaUInfo")).idPage) === 0
          ) {
            return (
              !isValidAddressData ||
              !isValidMultilevelData ||
              !isValidPaymentData
            );
          }
          return !isValidAddressData || !isValidPaymentData;
        default:
      }
    }
  };

  //Paypal
  const handleOrderCreatorPaypal = () => {
    //const { sponsor, identification } = clientInfoToCloseOrder;
    // if (
    //   benefitType === 1 &&
    //   sponsor <= 0 //|| identification.length <= 0
    // ) {
    //   enqueueSnackbar(t("shopping__payment__complete_info_message"), {
    //     variant: "warning",
    //   });
    //   history.replace("/checkout");
    // } else {
    return Payment.CreateOrderOnPaypal(order.orderId)
      .then((res) => {
        if (res.status === 401) {
          return res.json();
        } else {
          return res.json();
        }
      })
      .then((data) => {
        return data.id;
      })
      .catch((err) => {});
    //}
  };
  const handleOrderAprovedPaypal = (data) => {
    const model = {
      orderId: data.orderID,
      benefitType,
      evaluatept: true,
    };

    return Payment.AproveOrderOnPaypal(model)
      .then((res) => {
        if (res.status === 401) {
          //setFormUserData(initialStateFormUserData);
          return res.json();
        } else {
          return res.json();
        }
      })
      .then((details) => {
        //console.log("onAproved", details);
        const result = JSON.parse(details.responseModel);
        if (result.status === "COMPLETED") {
          ///COMPLETADO
          setPaypalStatusPay(details.status);
          enqueueSnackbar(t("__message_pay_success_label"), {
            variant: "success",
          });

          //FIXME: CAMBIAR EL CIERRE DE LA ORDEN POR UN SAGA
          //ASILADO QUE TRAIGA
          //RESPONSE_FIRST_CHARGE_SITE_RELOAD
          //dispatch(requestCloseCartOrder(benefitType));
          dispatch(requestFirstChargeSiteReload(details.user));

          dispatch(cleanCartClosedOrder());
          history.replace(`/summary/${previousSessionId}/${orderCloseType}`);
        } else {
          //No se pudo hacer el pago en paypal
          enqueueSnackbar(
            t("shopping__payment__error_pay_for_paypal_info_message"),
            { variant: "error" }
          );
        }
      })
      .catch((err) => {});
  };

  //New
  const handlePayButtonAction = () => {
    setBlockByPayingOp(true);
    if (!clientInfoToCloseOrder?.name) {
      console.log("No esta logueado");
      const date = new Date(formData.birthdate.value);
      const formatedBirthDate = moment(date).format("YYYY-MM-DD");
      const model = {
        type: "VDZ",
        channel: "ZU",
        cellPhone: formData.cellphone.value,
        email: formData.email.value,
        name: formData.name.value,
        lastName: formData.lastname.value,
        motherLastName: "",
        birthdate: formatedBirthDate, //benefitType === 1 ? clientInfoToCloseOrder.birthdate : "1900-01-01",
        street: formData.fiscalStreet.value,
        suite: formData.fiscalSuite.value.trim(),
        state: formData.fiscalState.value,
        city: formData.fiscalCity.value,
        zipCode: formData.fiscalZipCode.value,
        userId: 1,
      };
      console.log("handlePayButtonAction model", model);
      dispatch(requestUserDataValidation(model));
    } else {
      console.log("Actualizar direccion de envio y procesar pago");
      const model = {
        streetS: formData.shippStreet.value,
        suiteS: formData.shippSuite.value.trim(),
        cityS: formData.shippCity.value,
        stateS: formData.shippState.value,
        zipS: formData.shippZipCode.value,
        streetF: formData.fiscalStreet.value,
        suiteF: formData.fiscalSuite.value.trim(),
        cityF: formData.fiscalCity.value,
        stateF: formData.fiscalState.value,
        zipF: formData.fiscalZipCode.value,
        patrocinador: sponsorInformation.noEnterprising,
        typePlan: benefitType,
      };
      console.log("requestUpdateAddressCheckoutData model", model);
      dispatch(requestUpdateAddressCheckoutData(model));
    }
  };
  const handleDispatchPaymentAndOrderClose = () => {
    const email = clientInfoToCloseOrder.name
      ? clientInfoToCloseOrder.email
      : formData.email.value;

    if (isCreditCard && !isPaypalPay) {
      console.log("requestStripePayment2023", {
        email: email.trim(),
        cardNumber: creditCardData.cardNumber,
        cardExpirationDate: creditCardData.cardExpirationDate,
        cardCvc: creditCardData.cardCvc,
        orderId: order.orderId,
        benefitType,
      });

      dispatch(
        requestStripePayment2023(
          email.trim(),
          creditCardData.cardNumber,
          creditCardData.cardExpirationDate,
          creditCardData.cardCvc,
          order.orderId,
          benefitType
        )
      );
    } else if (!isCreditCard && isPaypalPay) {
      //PAYPAL
      //Manejar un state para mostar el boton de paypal
      setPaypalStep(2);
    }
  };

  return (
    <div
      id="firstWrapperInBaseContainer"
      className="firstWrapperInBaseContainer"
    >
      <div
        id="firstWrapperInBaseContainer"
        className={`shopping__payment__container ${
          isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
        }`}
      >
        {/* header */}
        <div
          className={`shopping__payment__container__header ${
            isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
          }`}
        >
          <h3 className="shopping__payment__container__header__title">
            {isLanguageLoaded ? (
              t("__breadcrumbs_navigation_/checkout")
            ) : (
              <Skeleton width={150} height={20} variant="wave" />
            )}
          </h3>
          {clientInfoToCloseOrder?.name && (
            <div
              className={`shopping__payment__container__header__inner ${
                isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
              }`}
            >
              {/* tarjeta de informacion de usuario */}
              <div
                className={`shopping__payment__container__header__inner__info__user ${
                  isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
                }`}
              >
                <div
                  className={`shopping__payment__user ${
                    isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
                  }`}
                >
                  <div
                    className={`shopping__payment__user__image ${
                      isProfileImageLoaded ? "" : "with__initials"
                    }`}
                  >
                    {isProfileImageLoaded ? (
                      <img
                        // src={
                        //   process.env.REACT_APP_STAGE === "dev"
                        //     ? `http://10.10.1.16:8082/imageProfile/image_profile${userId}.jpg?V=${Math.floor(
                        //         Math.random() * 100
                        //       )}`
                        //     : `https://www.zermatusa.com/imageProfile/image_profile${userId}.jpg?V=${Math.floor(
                        //         Math.random() * 100
                        //       )}`
                        // }
                        src={imageProfileUrl}
                        alt=""
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = AvatarPlaceholder;
                        }}
                      />
                    ) : (
                      <span>
                        {convertNameToInitials(
                          `${clientInfoToCloseOrder.name} ${clientInfoToCloseOrder.lastName}`
                        )}
                      </span>
                    )}
                  </div>
                  <div className="shopping__payment__user__info">
                    {orderCloseType !== "" && (
                      <>
                        {" "}
                        <span className="shopping__payment__user__info__title">
                          {`${clientInfoToCloseOrder.name} ${clientInfoToCloseOrder.lastName}`}
                        </span>
                        <span className="shopping__payment__user__info__subtitle">
                          {orderCloseType === "finalClient"
                            ? t("__checkout_customer_final_label")
                            : orderCloseType === "preferredClient"
                            ? t("__checkout_favorite_customer_label")
                            : t("__checkout_entrepreneur_label")}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* seccion general de tarjetas */}
        <div
          className={`shopping__payment__container__boxMain ${
            isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
          }`}
        >
          <div
            className={`shopping__payment__container__boxMain__wrapper ${
              isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
            }`}
          >
            <div
              className={`shopping__payment__container__boxMain__wrapper__left ${
                isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
              }`}
            >
              {/* login / nuevo usuario */}
              {!clientInfoToCloseOrder?.allReadyRegister && (
                //true &&
                <div
                  className={`shopping__payment__container__userData ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <div className="login__seccion">
                    <Link
                      onClick={() => {
                        setIsOpenLoginModal(true);
                      }}
                    >
                      {isLanguageLoaded ? (
                        t("__checkout_order_login_label")
                      ) : (
                        <Skeleton width={250} height={30} variant="wave" />
                      )}
                    </Link>
                  </div>
                  <div className="line__divider">
                    <hr /> o <hr />
                  </div>
                  <div className="createUser__seccion new__account ">
                    <div className="new__login__step__form">
                      <div
                        className={`new__login__step__form__content ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                      >
                        <NewRecord
                          isFromLogin={false}
                          isLanguageLoaded={isLanguageLoaded}
                          enqueueSnackbar={enqueueSnackbar}
                          ////
                          formData={formData}
                          setFormData={setFormData}
                          setGender={setGender}
                          acceptTermsAndConditions={acceptTermsAndConditions}
                          setAcceptTermsAndConditions={
                            setAcceptTermsAndConditions
                          }
                          benefitType={benefitType}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* direccion */}
              <Box
                className={`shopping__payment__container__boxMain__address first ${
                  isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
                }`}
              >
                <AddressSection
                  isLanguageLoaded={isLanguageLoaded}
                  t={t}
                  isFromLogin={false}
                  enqueueSnackbar={enqueueSnackbar}
                  ////
                  formData={formData}
                  setFormData={setFormData}
                  addressControl={addressControl}
                  setAddressControl={setAddressControl}
                  //////
                  benefitType={benefitType}
                  clientInfoToCloseOrder={clientInfoToCloseOrder}
                  setClientInfoToCloseOrder={setClientInfoToCloseOrder}
                  hanldeTaxCalculationOnOrder={hanldeTaxCalculationOnOrder}
                />
              </Box>

              {/* patrocinador */}
              {benefitType === 1 &&
                parseInt(
                  JSON.parse(localStorage.getItem("ZUsaUInfo")).typeUser
                ) !== 2 &&
                parseInt(
                  JSON.parse(localStorage.getItem("ZUsaUInfo")).idPage
                ) === 0 && (
                  <div
                    className={`shopping__payment__container__boxMain__sponsor ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <AssignEntrepreneur
                      buildVCardSponsorSelection={buildVCardSponsorSelection}
                      sponsorInformation={sponsorInformation}
                      setSponsorInformation={setSponsorInformation}
                    />
                  </div>
                )}

              {/* pago */}
              <div
                className={`shopping__payment__container__security ${
                  isTablet ? "tablet" : isMobile ? "mobile" : ""
                }`}
              >
                <Box
                  className={`shopping__payment__container__boxMain__security ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <div
                    className={`shopping__payment__container__boxMain__paySafely ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    <div
                      className={`shopping__payment__container__boxMain__header ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <div className="shopping__payment__container__boxMain__header__icon__wrapper">
                        <PaySecurityIcon />
                      </div>

                      <div className="shopping__payment__container__boxMain__header__inner__wrapper">
                        <Typography className="shopping__payment__container__boxMain__header__title">
                          {isLanguageLoaded ? (
                            t("shopping__payment__headers__security")
                          ) : (
                            <Skeleton width={150} height={30} variant="wave" />
                          )}
                        </Typography>
                      </div>
                    </div>
                    <div
                      className={`shopping__payment__container__boxMain__payment ${
                        isTablet ? "tablet" : isMobile ? "mobile" : ""
                      }`}
                    >
                      <div className="shopping__payment__container__boxMain__payment__checks">
                        <div
                          className={`shopping__payment__container__boxMain__payment__item ${
                            isTablet ? "tablet" : isMobile ? "mobile" : ""
                          } `}
                        >
                          <CheckboxComponent
                            className="shopping"
                            valueChecked={wayToPAy === "paypal" ? true : false}
                            valueCheck="paypal"
                            labelCheckbox={
                              isLanguageLoaded
                                ? t(
                                    "__checkout_order_steps_pay_register_pay_paypal"
                                  )
                                : ""
                            }
                            handleCheck={handleWayToPayChange}
                          />
                        </div>
                        <div
                          className={`shopping__payment__container__boxMain__payment__item ${
                            isTablet ? "tablet" : isMobile ? "mobile" : ""
                          } `}
                        >
                          <CheckboxComponent
                            className="shopping"
                            valueChecked={wayToPAy === "credit" ? true : false}
                            valueCheck="credit"
                            labelCheckbox={
                              isLanguageLoaded
                                ? t(
                                    "__checkout_order_steps_pay_register_pay_credit"
                                  )
                                : ""
                            }
                            handleCheck={handleWayToPayChange}
                          />
                        </div>
                      </div>
                    </div>
                    {isPaypalPay && (
                      <div
                        className={`shopping__payment__container__boxMain__paypal ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span
                            style={{ marginBottom: 16, fontWeight: "bold" }}
                          >
                            {t("__general__paypal__title")}
                          </span>

                          {paypalStep === 1 ? (
                            <>
                              <span>{t("__general__paypal__step1")}</span>
                              <button
                                disabled={handleIsDisabledPayButton()}
                                style={{
                                  backgroundColor: "#FFC439",
                                  border: "none",
                                  width: "100%",
                                  marginBottom: 10,
                                  height: 45,
                                  borderRadius: 4,
                                  fontWeight: "bold",
                                  fontStyle: "italic",
                                  fontSize: 18,
                                  fontFamily: "Roboto, sans-serif",
                                  cursor: handleIsDisabledPayButton()
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                onClick={handlePayButtonAction}
                              >
                                <span
                                  style={{
                                    color: handleIsDisabledPayButton()
                                      ? "gray"
                                      : "#003087",
                                  }}
                                >
                                  {t("__general__paypal__confirm")}
                                </span>
                                <span
                                  style={{
                                    color: handleIsDisabledPayButton()
                                      ? "gray"
                                      : "#0094D3",
                                    marginLeft: 3,
                                  }}
                                >
                                  {t("__general__paypal__data")}
                                </span>
                              </button>
                            </>
                          ) : (
                            paypalStep === 2 && (
                              <>
                                <span>{t("__general__paypal__step2")}</span>
                                <PayPalButton
                                  userFormData={clientInfoToCloseOrder}
                                  paypalStatusPay={paypalStatusPay}
                                  mode="sandbox"
                                  createOrder={handleOrderCreatorPaypal}
                                  onApprove={handleOrderAprovedPaypal}
                                  onCancel={(data) => {
                                    console.error("onCalcelPaypal", data);
                                  }}
                                  onError={(error) => {
                                    console.error("onErrorPaypal", error);
                                  }}
                                />
                              </>
                            )
                          )}
                        </div>
                      </div>
                    )}
                    {isCreditCard && (
                      <div
                        className={`shopping__payment__container__boxMain__payment__cardNumber ${
                          isTablet ? "tablet" : isMobile ? "mobile" : ""
                        }`}
                      >
                        <CreditCardForm
                          t={t}
                          withButton={false}
                          creditCardData={creditCardData}
                          setCreditCardData={setCreditCardData}
                        />
                      </div>
                    )}
                  </div>
                </Box>
              </div>
            </div>

            {/* productos */}
            <div
              className={`shopping__payment__container__boxMain__wrapper__right ${
                isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
              }`}
            >
              <Box
                className={`shopping__payment__container__boxMain__products ${
                  isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
                }`}
              >
                <div
                  className={`shopping__payment__container__boxMain__header ${
                    isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
                  }`}
                >
                  <div className="shopping__payment__container__boxMain__header__icon__wrapper">
                    <BoxOpenIcon />
                  </div>
                  {/* <div className="boxOpenIcon " /> */}
                  <div className="shopping__payment__container__boxMain__header__inner__wrapper">
                    {isLanguageLoaded ? (
                      <Typography
                        className={`shopping__payment__container__boxMain__header__title ${
                          isMobile && !isTablet
                            ? "mobile"
                            : isTablet
                            ? "tablet"
                            : ""
                        }`}
                      >
                        {t("shopping__payment__headers__products")}
                      </Typography>
                    ) : (
                      <Skeleton variant="rect" width={"10%"} height={30} />
                    )}
                  </div>
                </div>
                <div
                  className={`shopping__payment__container__boxMain__products__list ${
                    isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
                  }`}
                >
                  {orderLines.length > 0 ? (
                    <SummaryCheckout
                      orderLines={orderLines.filter(
                        (l) => l.line.tipolinea !== "R"
                      )}
                      showPrice={true}
                    />
                  ) : (
                    [0, 1, 2].map((itemX) => (
                      <Skeleton
                        key={itemX}
                        width={"100%"}
                        height={100}
                        style={{ marginBottom: 5 }}
                      />
                    ))
                  )}
                </div>
                {orderLines.filter((l) => l.line.tipolinea === "R").length >
                  0 && (
                  <div>
                    <div
                      className={`shopping__payment__container__boxMain__header ${
                        isMobile && !isTablet
                          ? "mobile"
                          : isTablet
                          ? "tablet"
                          : ""
                      }`}
                    >
                      <div className="shopping__payment__container__boxMain__header__icon__wrapper">
                        <SurpriseIcon />
                      </div>
                      {/* <div className="surpriseIcon " /> */}
                      <div className="shopping__payment__container__boxMain__header__inner__wrapper">
                        {isLanguageLoaded ? (
                          <Typography
                            className={`shopping__payment__container__boxMain__header__title ${
                              isMobile && !isTablet
                                ? "mobile"
                                : isTablet
                                ? "tablet"
                                : ""
                            }`}
                          >
                            {t("shopping__payment__headers__my__gifts")}
                          </Typography>
                        ) : (
                          <Skeleton variant="rect" width={"10%"} height={30} />
                        )}
                      </div>
                    </div>
                    <div
                      className={`shopping__payment__container__boxMain__products__gifts ${
                        isMobile && !isTablet
                          ? "mobile"
                          : isTablet
                          ? "tablet"
                          : ""
                      }`}
                    >
                      {orderLines.length > 0 ? (
                        <SummaryCheckout
                          orderLines={orderLines.filter(
                            (l) => l.line.tipolinea === "R"
                          )}
                          showPrice={false}
                        />
                      ) : (
                        [0, 1, 2].map((itemX) => (
                          <Skeleton
                            key={itemX}
                            width={"100%"}
                            height={100}
                            style={{ marginBottom: 5 }}
                          />
                        ))
                      )}
                    </div>
                  </div>
                )}

                <div className="custom__separator"></div>

                <div
                  className={`shopping__payment__container__boxMain__extras ${
                    isMobile && !isTablet ? "mobile" : isTablet ? "tablet" : ""
                  }`}
                >
                  {orderCloseType === "entreprenour" && (
                    <div className="shopping__payment__container__boxMain__extras__item">
                      <div>
                        {" "}
                        <CheckboxComponent
                          className="shopping"
                          valueChecked={shippingPass}
                          valueCheck={shippingPass}
                          labelCheckbox={
                            isLanguageLoaded
                              ? t(
                                  "shopping__payment__extra__shipping_pass__title"
                                )
                              : ""
                          }
                          handleCheck={handleShippingPass}
                        />
                      </div>
                      <div className="shopping__payment__container__boxMain__extras__info">
                        {isLanguageLoaded ? (
                          <Fragment>
                            <span>
                              {t(
                                "shopping__payment__extra__shipping_pass__subtitle"
                              )}
                            </span>
                            <span>$ 14.99</span>
                          </Fragment>
                        ) : (
                          <Skeleton variant="rect" width={"100%"} height={30} />
                        )}
                      </div>
                    </div>
                  )}
                  <div className="shopping__payment__container__boxMain__extras__item">
                    <div>
                      {" "}
                      <CheckboxComponent
                        valueCheck={securityPass}
                        valueChecked={securityPass}
                        labelCheckbox={
                          isLanguageLoaded
                            ? t("shopping__payment__extra__secury_pass__title")
                            : ""
                        }
                        handleCheck={handleSecurityPass}
                      />
                    </div>
                    <div className="shopping__payment__container__boxMain__extras__info">
                      {isLanguageLoaded ? (
                        <Fragment>
                          <span>
                            {t(
                              "shopping__payment__extra__secury_pass__subtitle"
                            )}
                          </span>
                          <span>$ 2.00</span>
                        </Fragment>
                      ) : (
                        <Skeleton variant="rect" width={"100%"} height={30} />
                      )}
                    </div>
                  </div>
                </div>

                {formData.shippState.value === "TX" &&
                  addressControl.isShippValidAddress && (
                    <div
                      className={`shopping__payment__container__boxMain__products__warning ${
                        isMobile && !isTablet
                          ? "mobile"
                          : isTablet
                          ? "tablet"
                          : ""
                      }`}
                    >
                      <Fragment>
                        <div className="summary__checkout__content__info__left">
                          {t(
                            "__checkout_order_steps_pay_summary__taxes__subtitle"
                          )}
                          <br />
                          <>
                            {orderCloseType.length > 0 ? (
                              <div
                                className="summary__checkout__content__info__left__link"
                                onClick={handleTaxes}
                              >
                                {t(
                                  "__checkout_order_steps_pay_summary__ref__txt"
                                )}
                              </div>
                            ) : (
                              <Fragment>
                                <Skeleton width={"100%"} height={30} />
                              </Fragment>
                            )}
                          </>
                          {isTaxesActive && (
                            <div className="summary__checkout__content__info__left__info">
                              {t(
                                "__policesShipping_subtitle_Re-delivery_charge__texas_Txt"
                              )}
                            </div>
                          )}
                        </div>
                      </Fragment>
                    </div>
                  )}

                <div
                  className={`shopping__payment__container__boxMain__products__totals ${
                    isTablet ? "tablet" : isMobile ? "mobile" : ""
                  }`}
                >
                  <div
                    className={`shopping__payment__container__boxMain__products__totals__inner ${
                      isTablet ? "tablet" : isMobile ? "mobile" : ""
                    }`}
                  >
                    {orderCloseType.length > 0 ? (
                      couponList.length > 0 && (
                        <SelectComponent
                          labelName={
                            isLanguageLoaded
                              ? t(
                                  "__myAccount_Profile_Information_My_Coupons_Modal_Title_Label"
                                )
                              : ""
                          }
                          listOptions={couponList}
                          textOption="name"
                          valueIsObject
                          isPersonalized
                          onChange={(e) => {
                            handleClientCouponSelection(e);
                          }}
                          value={clientCouponSelected}
                        />
                      )
                    ) : (
                      <Skeleton
                        width={"90%"}
                        height={50}
                        style={{ marginLeft: 20 }}
                      />
                    )}
                  </div>
                  <div className="checkout__detail__wrapper__right">
                    <div className="checkout__detail__wrapper__right__prices">
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        <div className="checkout__detail__wrapper__item">
                          <div className="checkout__detail__wrapper__item__left">
                            {order.hasOwnProperty("orderId") &&
                            isLanguageLoaded ? (
                              t("__checkout_order_subtotal")
                            ) : (
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={10}
                                style={{ marginBottom: 5 }}
                              />
                            )}
                          </div>
                          <div className="checkout__detail__wrapper__item__right">
                            {order.hasOwnProperty("orderId") &&
                            isLanguageLoaded ? (
                              <> ${parseFloat(order.subtotal).toFixed(2)}</>
                            ) : (
                              <Skeleton
                                variant="rect"
                                width={isMobile && !isTablet ? 200 : "100%"}
                                height={10}
                                style={{ marginBottom: 5 }}
                              />
                            )}
                          </div>
                        </div>

                        {/* {console.log("ORDER CHECKOUT", order)} */}

                        <div className="checkout__detail__wrapper__item">
                          <div className="checkout__detail__wrapper__item__left">
                            {order.hasOwnProperty("orderId") &&
                            isLanguageLoaded ? (
                              t("__checkout_order_charges")
                            ) : (
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={10}
                                style={{ marginBottom: 5 }}
                              />
                            )}
                          </div>
                          <div className="checkout__detail__wrapper__item__right">
                            {order.hasOwnProperty("orderId") &&
                            isLanguageLoaded ? (
                              <> ${parseFloat(order.cargos).toFixed(2)}</>
                            ) : (
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={10}
                                style={{ marginBottom: 5 }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="checkout__detail__wrapper__item">
                          <div className="checkout__detail__wrapper__item__left">
                            {order.hasOwnProperty("orderId") &&
                            isLanguageLoaded ? (
                              t("__checkout_order_taxes")
                            ) : (
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={10}
                                style={{ marginBottom: 5 }}
                              />
                            )}
                          </div>
                          <div className="checkout__detail__wrapper__item__right">
                            {order.hasOwnProperty("orderId") &&
                            isLanguageLoaded ? (
                              <>${parseFloat(order.importeimp).toFixed(2)}</>
                            ) : (
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={10}
                                style={{ marginBottom: 5 }}
                              />
                            )}
                          </div>
                        </div>
                        <div className="checkout__detail__wrapper__item">
                          <div className="checkout__detail__wrapper__item__left">
                            {order.hasOwnProperty("orderId") &&
                            isLanguageLoaded ? (
                              t("__checkout_order_credit")
                            ) : (
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={10}
                                style={{ marginBottom: 5 }}
                              />
                            )}
                          </div>
                          <div className="checkout__detail__wrapper__item__right">
                            {order.hasOwnProperty("orderId") &&
                            isLanguageLoaded ? (
                              <>${parseFloat(order.abonos).toFixed(2)}</>
                            ) : (
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={10}
                                style={{ marginBottom: 5 }}
                              />
                            )}
                          </div>
                        </div>

                        {benefitType !== 5 &&
                          order.importeii !== order.importeii2 && (
                            <Fragment>
                              <div className="checkout__detail__wrapper__item">
                                <div
                                  style={{
                                    fontWeight: "normal",
                                    color: "dimgray",
                                  }}
                                  className="checkout__detail__wrapper__item__left__total"
                                >
                                  {order.hasOwnProperty("orderId") &&
                                  isLanguageLoaded ? (
                                    benefitType === 2 ? (
                                      isLanguageLoaded ? (
                                        t("__general__total__with__membership")
                                      ) : (
                                        ""
                                      )
                                    ) : benefitType === 1 ? (
                                      isLanguageLoaded ? (
                                        t("__general__total__with__kit")
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    <Skeleton
                                      variant="rect"
                                      width={"100%"}
                                      height={10}
                                      style={{ marginBottom: 5 }}
                                    />
                                  )}
                                </div>
                                <div
                                  className="checkout__detail__wrapper__item__right__total"
                                  style={{
                                    fontWeight: "normal",
                                    color: "dimgray",
                                  }}
                                >
                                  {order.hasOwnProperty("orderId") &&
                                  isLanguageLoaded ? (
                                    <>
                                      {" "}
                                      ${parseFloat(order.importeii2).toFixed(2)}
                                    </>
                                  ) : (
                                    <Skeleton
                                      variant="rect"
                                      width={
                                        isMobile && !isTablet ? 200 : "100%"
                                      }
                                      height={10}
                                      style={{ marginBottom: 5 }}
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="checkout__detail__wrapper__item">
                                <div
                                  style={{
                                    fontWeight: "normal",
                                    color: "orange",
                                  }}
                                  className="checkout__detail__wrapper__item__left__total"
                                >
                                  {order.hasOwnProperty("orderId") &&
                                  isLanguageLoaded ? (
                                    t("__general__yout__saved")
                                  ) : (
                                    <Skeleton
                                      variant="rect"
                                      width={"100%"}
                                      height={10}
                                      style={{ marginBottom: 5 }}
                                    />
                                  )}
                                </div>
                                <div
                                  className="checkout__detail__wrapper__item__right__total"
                                  style={{
                                    fontWeight: "normal",
                                    color: "orange",
                                  }}
                                >
                                  {order.hasOwnProperty("orderId") &&
                                  isLanguageLoaded ? (
                                    <>
                                      {" "}
                                      $
                                      {parseFloat(
                                        order.importeii2 - order.importeii
                                      ).toFixed(2)}
                                    </>
                                  ) : (
                                    <Skeleton
                                      variant="rect"
                                      width={
                                        isMobile && !isTablet ? 200 : "100%"
                                      }
                                      height={10}
                                      style={{ marginBottom: 5 }}
                                    />
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          )}

                        <div className="checkout__detail__wrapper__item">
                          <div
                            style={{ fontWeight: "bold" }}
                            className="checkout__detail__wrapper__item__left__total"
                          >
                            {order.hasOwnProperty("orderId") &&
                            isLanguageLoaded ? (
                              t("__checkout_order_total")
                            ) : (
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={10}
                                style={{ marginBottom: 5 }}
                              />
                            )}
                          </div>
                          <div className="checkout__detail__wrapper__item__right__total">
                            {order.hasOwnProperty("orderId") &&
                            isLanguageLoaded ? (
                              <> ${parseFloat(order.importeii).toFixed(2)}</>
                            ) : (
                              <Skeleton
                                variant="rect"
                                width={"100%"}
                                height={10}
                                style={{ marginBottom: 5 }}
                              />
                            )}
                          </div>
                        </div>
                      </Typography>
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
      {/* bottones de pago */}
      <div
        className={`shopping__payment__container__buttons ${
          isTablet ? "tablet" : isMobile ? "mobile" : ""
        }`}
      >
        <div
          className={`shopping__payment__container__buttons__left ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          }`}
        >
          {isLanguageLoaded ? (
            <>
              {isCreditCard && !isPaypalPay && !blockByPayingOp && (
                <Button
                  disabled={handleIsDisabledPayButton()}
                  onClick={() => {
                    handlePayButtonAction();
                  }}
                  className="button__with__icon bg__dark__pink no__left__margin"
                >
                  <div className="button__with__icon__svg__wrapper dark__pink__ball">
                    <div
                      className={`${
                        creditCardData.cardNumber.length > 0 &&
                        creditCardData.cardExpirationDate.length > 0 &&
                        creditCardData.cardCvc.length > 0
                          ? "bagWhiteIcon"
                          : "bagGrayIcon"
                      }`}
                    />
                  </div>
                  <span>{isLanguageLoaded ? t("btn_cart_text_pay2") : ""}</span>
                </Button>
              )}
            </>
          ) : (
            <Skeleton width={200} height={50} variant="wave" />
          )}
        </div>
        <div
          className={`shopping__payment__container__buttons__right ${
            isTablet ? "tablet" : isMobile ? "mobile" : ""
          } `}
        >
          {isLanguageLoaded ? (
            <>
              {!blockByPayingOp && (
                <Button
                  disabled={blockByOrderCancelling}
                  onClick={() => {
                    setBlockByOrderCancelling(true);
                    dispatch(requestCartCancelOrder());
                  }}
                  className="button__with__icon bg__pink no__left__margin"
                >
                  <div className="button__with__icon__svg__wrapper pink__ball">
                    <div className="bagRedIcon" />
                  </div>
                  <span className="text__red">
                    {isLanguageLoaded ? t("btn_completed_later") : ""}
                  </span>
                </Button>
              )}
            </>
          ) : (
            <Skeleton width={200} height={50} variant="wave" />
          )}
        </div>
      </div>
      {/* modal login */}
      <CenteredModal
        open={isOpenLoginModal}
        message={
          <Fragment>
            <NewLoginContainer
              handleGAEvent={handleGAEvent}
              enqueueSnackbar={enqueueSnackbar}
              isLanguageLoaded={isLanguageLoaded}
              isFromCheckout={true}
              setIsOpenLoginModal={setIsOpenLoginModal}
            />
          </Fragment>
        }
        handleClose={() => {
          setIsOpenLoginModal(false);
        }}
      />
      {/* modal datos duplicados */}
      <CenteredModal
        open={isOpenValidationDataModal}
        title={t("__centered_modal_validation_title")}
        message={
          <ul className="centered__modal__content__list__warning">
            {userDataValidationErrors.length > 0 &&
              userDataValidationErrors.map((error, index) => (
                <li key={index}>{t(error.validationDescription)}</li>
              ))}
          </ul>
        }
        children={
          <Fragment>
            {/* <span className=" centered__modal__content__note__warning text__red">
              {t("__client_data_validation_error_noti_partTwo")}
            </span>
            <span className=" centered__modal__content__note__warning text__red">
              {t("__client_data_validation_error_noti_partThree")}
            </span>
            <span className=" centered__modal__content__note__warning text__red">
              {t("__client_data_validation_error_noti_partOne")}
            </span> */}
            <span className=" centered__modal__content__note__warning text__red">
              {t("__centered_modal_validation_footer_warning")}
            </span>
          </Fragment>
        }
        handleClose={() => {
          setIsOpenValidationDataModal(false);
        }}
      />
      {/* modal registro completado  */}
      <CenteredModal
        open={isOpenCongraModal}
        title={t("__login_Create_Account_Registration_Success_Title")}
        message={t("__login_Create_Account_Registration_Success_Message")}
        handleClose={() => {
          setIsOpenCongraModal(false);
        }}
        handleConfirm={() => {
          setIsOpenCongraModal(false);
        }}
        alternateConfirmLabel={t("__checkout_order_finish_register_label")}
      />
    </div>
  );
};

export default withRouter(CheckOutContainer);
