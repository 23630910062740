import {
  REQUEST_CLIENT_LOGIN,
  REQUEST_REGISTER_NEW_CLIENT,
  REQUEST_CLIENT_INFORMATION,
  REQUEST_WEBPAGE_CONTACT_REGISTER,
  CLEAN_REDUCER_WEBPAGE_CONTACT_REGISTER,
  REQUEST_CLIENT_ADMINISTRATION_MENU,
  REQUEST_CLIENT_LOGOUT,
  CLEAN_REDUCER_CLIENT_REGISTER,
  CLEAN_REDUCER_CLIENT_UPDATE_INFORMATION,
  REQUEST_UPDATE_CLIENT_INFORMATION,
  REQUEST_EMAIL_FROM_RETARGETING_PROGRAM,
  REQUEST_EMAIL_VALIDATION,
  REQUEST_PASSWORD_RECOVERY,
  REQUEST_VALIDATE_PASSWORD_RECOVERY_CODE,
  REQUEST_RESET_USER_PASSWORD,
  CLEAN_REDUCER_RECOVERY_CLIENT_PASSWORD,
  REQUEST_GET_CLIENT_CONFIRMED_ORDERS,
  REQUEST_VALIDATE_INITIAL_BANNER,
  REQUEST_VALIDATE_IDTYPE,
  CLEAN_VALIDATE_IDTYPE_REDUCER,
  REQUEST_UPDATE_DATA_FROM_CHECKOUT,
  CLEAN_REDUCER_CLIENT_ADDRESS_VALIDATION,
  REQUEST_INSERT_FISCAL_ADDRESS,
  CLEAN_USER_INFORMATION_REDUCER,
  CLEAN_CLIENT_LOGIN_REDUCER,
  CLEAN_REGISTER_FROM_CHEKOUT_REDUCER,
  REQUEST_VALIDATE_OWNER_EMAIL,
  CLEAN_REGISTER_FROM_CHEKOUT_FISCAL_ADDRESS_REDUCER,
  REQUEST_GET_DATA_SPONSOR_BY_ID,
  REQUEST_GET_DATA_SPONSOR_BY_NAME,
  REQUEST_GET_DATA_SPONSOR_BY_CITY,
  REQUEST_ASSIGN_SPONSOR,
  REQUEST_UPDATE_SHIPPING_ADDRESS,
  CLEAN_UPDATE_SHIPPING_ADDRESS,
  CLEAN_GET_DATA_SPONSOR_LIST,
  REQUEST_CLIENT_UPLOAD_IMAGE_PROFILE,
  CLEAN_CLIENT_UPLOAD_IMAGE_REDUCER,
  REQUEST_CLIENT_REGISTER_QUESTION_SUPPORT,
  CLEAN_CLIENT_SUPPORT_QUESTION_REDUCER,
  REQUEST_GET_DATA_SPONSOR_AUTOMATIC,
  REQUEST_ASSIGN_SPONSOR_AUTOMATIC,
  CLEAN_GET_DATA_SPONSOR_AUTOMATIC,
  REQUEST_CLIENT_IMMEDIATE_GAINS,
  CLEAN_CLIENT_IMMEDIATE_GAINS_REDUCER,
  CLEAN_CLIENT_RETARGETING_PROGRAM,
  REQUEST_CLIENT_GUEST_INFORMATION_EMAIL,
  CLEAN_CLIENT_GUEST_INFORMATION_EMAIL,
  REQUEST_NEWSLETTER_SUSCRIBE_EVALUATION,
  CLEAN_NEWSLETTER_SUSCRIBE_VALIDATION,
  CLEAN_ASSGIN_SPONSOR_REDUCER,
  CLEAN_REDUCER_PASSWORD_RECOVERY,
  REQUEST_NEWMEMBER_CLOSE_ACTUAL_SESSION,
  REQUEST_USER_DATA_VALIDATION,
  CLEAN_USER_DATA_VALIDATION,
  //////
  REQUEST_REGISTRATION_LOGIN_DATA,
  REQUEST_NEW_REGISTRATION_CHECKOUT_DATA,
  REQUEST_UPDATE_ADDRESS_CHECKOUT_DATA,
  REQUEST_LANDING_NEW_REGISTRATION_DATA,
  CLEAN_LANDING_NEW_REGISTRATION_REDUCER
} from "./../../Constants";
import {
  deviceDetect,
  isMobile,
  isTablet,
  isDesktop,
} from "react-device-detect";

export const requestClientLogin = (userName, password) => {
  let user = {};
  let userGuid = "";
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUGuestId")) {
    userGuid = localStorage.getItem("ZUsaUGuestId");
  }
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  user = {
    userName,
    password,
    sessionId,
    userGuid,
  };
  return {
    type: REQUEST_CLIENT_LOGIN,
    payload: { user },
  };
};

export const requestRegisterNewClient = (
  name,
  lastname,
  email,
  cellPhone,
  password,
  acceptZermatNotifications,
  idLang,
  street,
  suite,
  city,
  state,
  zip, //fiscal
  street2,
  suite2,
  city2,
  state2,
  zip2 //envio
) => {
  const newRegister = {
    name,
    lastname,
    email,
    cellPhone,
    password,
    acceptZermatNotifications,
    idLang,
    street,
    suite,
    city,
    state,
    zip,
    street2,
    suite2,
    city2,
    state2,
    zip2,
  };
  let sessionId = "0";
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(
      localStorage.getItem("ZUsaUInfo")
    ).sessionId.toString();
  }
  newRegister.sessionId = sessionId;
  return {
    type: REQUEST_REGISTER_NEW_CLIENT,
    payload: { newRegister },
  };
};

export const requestClientInformation = () => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  return {
    type: REQUEST_CLIENT_INFORMATION,
    payload: { sessionId },
  };
};

export const requestClientUpdateInformation = (
  name,
  lastName,
  email,
  cellphone,
  password,
  street,
  suite,
  state,
  city,
  zip
) => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  const model = {
    sessionId,
    name,
    lastName,
    email,
    cellphone,
    password,
    zip,
    city,
    state,
    street,
    suite,
  };
  return {
    type: REQUEST_UPDATE_CLIENT_INFORMATION,
    payload: {
      model,
    },
  };
};

export const requestRegisterWebPageContact = (fullName, cellPhone, comment) => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  return {
    type: REQUEST_WEBPAGE_CONTACT_REGISTER,
    payload: { sessionId, name: fullName, cellPhone, comment },
  };
};

export const cleanReducerWebPageContactReducer = () => {
  return {
    type: CLEAN_REDUCER_WEBPAGE_CONTACT_REGISTER,
    payload: {},
  };
};
export const cleanReducerClientNewRegistration = () => {
  return {
    type: CLEAN_REDUCER_CLIENT_REGISTER,
    payload: {},
  };
};
export const cleanReducerClientUpdateInformation = () => {
  return {
    type: CLEAN_REDUCER_CLIENT_UPDATE_INFORMATION,
    payload: {},
  };
};

export const requestClientAdministrationMenu = () => {
  return {
    type: REQUEST_CLIENT_ADMINISTRATION_MENU,
    payload: {},
  };
};

export const requestClientLogOut = () => {
  const deviceDetector = deviceDetect();
  let modelFirstCharge = {};
  let userId = "";
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUGuestId")) {
    userId = localStorage.getItem("ZUsaUGuestId");
  }

  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  let urlPage = "";
  if (localStorage.getItem("ZUsaUrlPage")) {
    urlPage = localStorage.getItem("ZUsaUrlPage");
  }
  modelFirstCharge = {
    urlPage,
    browser:
      deviceDetector?.browserName === undefined
        ? ""
        : deviceDetector?.browserName,
    browserVersion:
      deviceDetector?.browserFullVersion === undefined
        ? ""
        : deviceDetector?.browserFullVersion,
    iP: "",
    os: deviceDetector?.osName === undefined ? "" : deviceDetector?.osName,
    osVersion:
      deviceDetector?.osVersion === undefined ? "" : deviceDetector?.osVersion,
    device: deviceDetector?.osName === undefined ? "" : deviceDetector?.osName,
    isDesktop,
    isMobile,
    isTablet,
    userAgent:
      deviceDetector?.userAgent === undefined ? "" : deviceDetector?.userAgent,
    email: "",
    userId,
    sessionId,
  };
  return {
    type: REQUEST_CLIENT_LOGOUT,
    payload: { modelFirstCharge },
  };
};

export const requestClientLogoutAndChargeNewMember = (urlPage) => {
  const deviceDetector = deviceDetect();
  let modelFirstCharge = {};
  let userId = "";
  let sessionId = 0;

  if (localStorage.getItem("ZUsaUGuestId")) {
    userId = localStorage.getItem("ZUsaUGuestId");
  }

  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  // let urlPage = "";
  // if (localStorage.getItem("ZUsaUrlPage")) {
  //   urlPage = localStorage.getItem("ZUsaUrlPage");
  // }
  modelFirstCharge = {
    urlPage,
    browser:
      deviceDetector?.browserName === undefined
        ? ""
        : deviceDetector?.browserName,
    browserVersion:
      deviceDetector?.browserFullVersion === undefined
        ? ""
        : deviceDetector?.browserFullVersion,
    iP: "",
    os: deviceDetector?.osName === undefined ? "" : deviceDetector?.osName,
    osVersion:
      deviceDetector?.osVersion === undefined ? "" : deviceDetector?.osVersion,
    device: deviceDetector?.osName === undefined ? "" : deviceDetector?.osName,
    isDesktop,
    isMobile,
    isTablet,
    userAgent:
      deviceDetector?.userAgent === undefined ? "" : deviceDetector?.userAgent,
    email: "",
    userId,
    sessionId,
  };
  return {
    type: REQUEST_NEWMEMBER_CLOSE_ACTUAL_SESSION,
    payload: { modelFirstCharge },
  };
};

export const requestEmailFromRetargetingProgram = (email) => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  return {
    type: REQUEST_EMAIL_FROM_RETARGETING_PROGRAM,
    payload: {
      email,
      sessionId,
    },
  };
};

export const requestCleanReducerRetargetingProgram = () => {
  return {
    type: CLEAN_CLIENT_RETARGETING_PROGRAM,
    payload: {},
  };
};

export const requestPasswordRecovery = (recoveryType, cellPhone, email) => {
  return {
    type: REQUEST_PASSWORD_RECOVERY,
    payload: {
      recoveryType,
      cellPhone,
      email,
    },
  };
};

export const requestValidationPasswordRecoveryCode = (code) => {
  return {
    type: REQUEST_VALIDATE_PASSWORD_RECOVERY_CODE,
    payload: {
      code,
    },
  };
};

export const requestResetUserPassword = (clientId, password) => {
  return {
    type: REQUEST_RESET_USER_PASSWORD,
    payload: {
      clientId,
      password,
    },
  };
};

export const requestCleanRecoveryPasswordStates = () => {
  return {
    type: CLEAN_REDUCER_RECOVERY_CLIENT_PASSWORD,
    payload: {},
  };
};

export const requestGetClientConfirmedOrders = () => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  return {
    type: REQUEST_GET_CLIENT_CONFIRMED_ORDERS,
    payload: { sessionId },
  };
};

export const requestValidateInitialBanner = () => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  return {
    type: REQUEST_VALIDATE_INITIAL_BANNER,
    payload: { sessionId },
  };
};
export const requestValidateIdentificationType = (
  clientId,
  idType,
  idNumber
) => {
  return {
    type: REQUEST_VALIDATE_IDTYPE,
    payload: { clientId, idType, idNumber },
  };
};
export const cleanValidateIdentificationTypeReducer = () => {
  return {
    type: CLEAN_VALIDATE_IDTYPE_REDUCER,
    payload: {},
  };
};
export const requestUpdateDateFromCheckout = (model) => {
  let sessionId = "0";
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(
      localStorage.getItem("ZUsaUInfo")
    ).sessionId.toString();
  }
  model.sessionId = sessionId;
  return {
    type: REQUEST_UPDATE_DATA_FROM_CHECKOUT,
    payload: {
      model,
    },
  };
};

export const requestCleanAddressReducer = () => {
  return {
    type: CLEAN_REDUCER_CLIENT_ADDRESS_VALIDATION,
    payload: {},
  };
};

export const requestCleanUserInformationReducer = () => {
  return {
    type: CLEAN_USER_INFORMATION_REDUCER,
    payload: {},
  };
};
export const requestCleanClientLoginReducer = () => {
  return {
    type: CLEAN_CLIENT_LOGIN_REDUCER,
    payload: {},
  };
};
export const requestRegisterFromCheckoutReducer = () => {
  return {
    type: CLEAN_REGISTER_FROM_CHEKOUT_REDUCER,
    payload: {},
  };
};

export const requestRegisterFromLandingReducer = () => {
  return {
    type: CLEAN_LANDING_NEW_REGISTRATION_REDUCER,
    payload: {},
  };
};

export const cleanAssingSponsorReducer = () => {
  return {
    type: CLEAN_ASSGIN_SPONSOR_REDUCER,
    payload: {},
  };
};
export const requestInsertFiscalAddress = (street, suite, state, city, zip) => {
  let clientId = "0";
  if (localStorage.getItem("ZUsaUInfo")) {
    clientId = JSON.parse(localStorage.getItem("ZUsaUInfo")).idClient;
  }
  return {
    type: REQUEST_INSERT_FISCAL_ADDRESS,
    payload: {
      clientId,
      street,
      suite,
      state,
      city,
      zip,
    },
  };
};
export const requestValidateOwnerEmail = (email) => {
  let sessionId = "0";
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(
      localStorage.getItem("ZUsaUInfo")
    ).sessionId.toString();
  }
  return {
    type: REQUEST_VALIDATE_OWNER_EMAIL,
    payload: {
      sessionId,
      email,
    },
  };
};
export const requestRegisterFromCheckoutFiscalAddressReducer = () => {
  return {
    type: CLEAN_REGISTER_FROM_CHEKOUT_FISCAL_ADDRESS_REDUCER,
    payload: {},
  };
};
export const requestGetDataSponsorById = (sponsorId) => {
  return {
    type: REQUEST_GET_DATA_SPONSOR_BY_ID,
    payload: {
      sponsorId,
    },
  };
};
export const requestGetDataSponsorByName = (name, lastName) => {
  return {
    type: REQUEST_GET_DATA_SPONSOR_BY_NAME,
    payload: {
      name,
      lastName,
    },
  };
};
export const requestGetDataSponsorByCity = (idState, city) => {
  return {
    type: REQUEST_GET_DATA_SPONSOR_BY_CITY,
    payload: {
      idState,
      city,
    },
  };
};
export const requestAssignSponsor = (sponsorId) => {
  let sessionId = "0";
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(
      localStorage.getItem("ZUsaUInfo")
    ).sessionId.toString();
  }
  return {
    type: REQUEST_ASSIGN_SPONSOR,
    payload: {
      sponsorId,
      sessionId,
    },
  };
};
export const requestUpdateShippingAddress = (
  street,
  suite,
  state,
  city,
  zip
) => {
  let sessionId = "0";
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(
      localStorage.getItem("ZUsaUInfo")
    ).sessionId.toString();
  }
  return {
    type: REQUEST_UPDATE_SHIPPING_ADDRESS,
    payload: {
      sessionId,
      street,
      suite,
      state,
      city,
      zip,
    },
  };
};
export const requestCleanUpdateShippingAddressReducer = () => {
  return {
    type: CLEAN_UPDATE_SHIPPING_ADDRESS,
    payload: {},
  };
};
export const requestCleanGetDataSponsorsListReducer = () => {
  return {
    type: CLEAN_GET_DATA_SPONSOR_LIST,
    payload: {},
  };
};

export const requestUploadProfileImage = (data, fileName) => {
  data = data.replace("data:image/png;base64,", "");
  return {
    type: REQUEST_CLIENT_UPLOAD_IMAGE_PROFILE,
    payload: {
      data,
      fileName,
    },
  };
};

export const requestCleanUploadImageReducer = () => {
  return {
    type: CLEAN_CLIENT_UPLOAD_IMAGE_REDUCER,
    payload: {},
  };
};
export const requestRegisterSupportQuestion = (
  name,
  email,
  cellPhone,
  question
) => {
  let sessionId = "0";
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(
      localStorage.getItem("ZUsaUInfo")
    ).sessionId.toString();
  }
  const model = {
    sessionId,
    name,
    email,
    cellPhone,
    question,
  };
  return {
    type: REQUEST_CLIENT_REGISTER_QUESTION_SUPPORT,
    payload: {
      model,
    },
  };
};

export const requestImmediateGains = (amount, paypalEmail) => {
  let sessionId = "0";
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(
      localStorage.getItem("ZUsaUInfo")
    ).sessionId.toString();
  }
  const model = {
    sessionId,
    amount,
    paypalEmail,
  };
  return {
    type: REQUEST_CLIENT_IMMEDIATE_GAINS,
    payload: {
      model,
    },
  };
};

export const requestCleanClientImmediateGainsReducer = () => {
  return {
    type: CLEAN_CLIENT_IMMEDIATE_GAINS_REDUCER,
    payload: {},
  };
};
export const requestCleanSupportQuestionReducer = () => {
  return {
    type: CLEAN_CLIENT_SUPPORT_QUESTION_REDUCER,
    payload: {},
  };
};

export const requestGetDataSponsorAutomatic = () => {
  return {
    type: REQUEST_GET_DATA_SPONSOR_AUTOMATIC,
    payload: {},
  };
};
export const requestAssignSponsorAutomatic = (sponsorId) => {
  let sessionId = "0";
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(
      localStorage.getItem("ZUsaUInfo")
    ).sessionId.toString();
  }
  return {
    type: REQUEST_ASSIGN_SPONSOR_AUTOMATIC,
    payload: {
      sponsorId,
      sessionId,
    },
  };
};
export const requestCleanGetDataSponsorsAutomaticReducer = () => {
  return {
    type: CLEAN_GET_DATA_SPONSOR_AUTOMATIC,
    payload: {},
  };
};

export const requestClientGuestInformationEmail = (email) => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  return {
    type: REQUEST_CLIENT_GUEST_INFORMATION_EMAIL,
    payload: {
      email,
      sessionId,
    },
  };
};

export const requestCleanClientGuestInformationEmailReducer = () => {
  return {
    type: CLEAN_CLIENT_GUEST_INFORMATION_EMAIL,
    payload: {},
  };
};
export const requestNewsletterEvaluation = (email) => {
  return {
    type: REQUEST_NEWSLETTER_SUSCRIBE_EVALUATION,
    payload: {
      email,
    },
  };
};
export const cleanNewsLetterSuscribeValidationReducer = () => {
  return {
    type: CLEAN_NEWSLETTER_SUSCRIBE_VALIDATION,
    payload: {},
  };
};

export const requestCleanPasswordRecoveryReducer = () => {
  return {
    type: CLEAN_REDUCER_PASSWORD_RECOVERY,
    payload: {},
  };
};
export const requestUserDataValidation = (model) => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }

  model.sessionId = sessionId;
  return {
    type: REQUEST_USER_DATA_VALIDATION,
    payload: { model },
  };
};
export const requestCleanUserDataValidation = () => {
  return {
    type: CLEAN_USER_DATA_VALIDATION,
    payload: {},
  };
};

//New
export const requestRegistrationLoginData = (model) => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  model.sessionId = sessionId;
  return {
    type: REQUEST_REGISTRATION_LOGIN_DATA,
    payload: {
      model,
    },
  };
};
export const requestNewRegistrationCheckoutData = (model) => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  model.sessionId = sessionId;
  return {
    type: REQUEST_NEW_REGISTRATION_CHECKOUT_DATA,
    payload: {
      model,
    },
  };
};
export const requestUpdateAddressCheckoutData = (model) => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  model.sessionId = sessionId;
  return {
    type: REQUEST_UPDATE_ADDRESS_CHECKOUT_DATA,
    payload: {
      model,
    },
  };
};
export const requestNewRegistrationLandingData = (model) => {
  let sessionId = 0;
  if (localStorage.getItem("ZUsaUInfo")) {
    sessionId = JSON.parse(localStorage.getItem("ZUsaUInfo")).sessionId;
  }
  model.sessionId = sessionId;
  return {
    type: REQUEST_LANDING_NEW_REGISTRATION_DATA,
    payload: {
      model,
    },
  };
};
